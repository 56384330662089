body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
  background-image: url('./images/background.jpeg');
  background-size: cover;
  background-attachment: fixed; 
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9); 
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.3);
}
  
nav {
  background-color: rgba(21, 26, 98, 0.5);
  color: white;
  padding: 30px;
  text-align: center;
}
  
nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

nav a {
  color: rgb(248, 244, 244);
  text-decoration: none;
  font-size: 18px; 
  font-weight: bold; 
}
  
footer {
  text-align: center;
  padding: 20px 0;
  /* border-top: 2px solid #333; */
  /* background-image: url('/images/footer-bg.jpg');
  background-size: cover; */
  color: white;
  margin-top: auto;
}