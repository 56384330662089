
.container {
    /* max-width: 1800; */
    margin: 0;
    padding: 0;
    text-align: left;
}
.novel {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}
.content {
    text-indent: 1em;
}
h2 {
    color: #333;
    margin-top: 0;
}
p {
    color: #666;
    /* text-indent: 2em;  */
}